'use client'

import { ViewPromotion } from 'analytics/components/ViewPromotion/ViewPromotion'
import type { Promotion } from 'analytics/types/EEC'
import { ClientCrossSelling } from 'cross-selling/components/shared/CrossSelling/client/ClientCrossSelling'
import type {
	CrossSellingServiceResponse,
	ProductImageType,
} from 'cross-selling/types'
import {
	type CarouselAction,
	type CarouselTitle,
	CarouselVariants,
} from 'fukku/Carousel/types'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import type { ErrorManager } from 'monitoring/components/ErrorManager/ErrorManager'
import { Domains } from 'monitoring/constants/domains'
import { sendError } from 'monitoring/sendError/server/sendError'
import type { ProductLocations } from 'product/types/ProductLocations'
import { useEffect, useState } from 'react'
import { useSession } from 'session/src/hooks/useSession'

import { getBestSellerProducts } from '../../../../services/getBestSellerProducts'

type CarouselBannerBestSellersProps = {
	imageType: ProductImageType
	title: CarouselTitle
	action: CarouselAction
	productLocation: ProductLocations
	variant: CarouselVariants
	promotion: Promotion
	className: string
	isPrioritySlot?: boolean
}
export function CarouselBannerBestSellers({
	imageType,
	title,
	action,
	productLocation,
	variant,
	promotion,
	className,
}: CarouselBannerBestSellersProps) {
	const { externalId } = useSession()
	const [products, setProducts] = useState<CrossSellingServiceResponse[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const {
		country: { countryISO, languageISO: language },
		brand,
		channel,
	} = useMasterData()

	useEffect(() => {
		async function fetchProducts() {
			try {
				const productsData = await getBestSellerProducts({
					countryISO,
					language,
					brand,
					channel,
					externalId: externalId ?? '',
				})
				setProducts(productsData)
				setLoading(false)
			} catch (error) {
				const errorManager = error as ErrorManager
				throw sendError({
					domain: Domains.Home,
					message: `CarouselBannerBestSellers getBestSellerProducts service: ${errorManager.message}`,
				})
			}
		}
		fetchProducts()
	}, [externalId])

	if (loading) {
		return null
	}

	if (!loading && !products?.length) {
		throw sendError({
			domain: Domains.Home,
			message: `CarouselBannerBestSellers: No products returned from getBestSellerProducts service`,
		})
	}
	return (
		<ViewPromotion promotions={[promotion]}>
			<ClientCrossSelling
				crossSellingItems={products}
				imageSortCriteria={[imageType]}
				title={title}
				action={action}
				className={className}
				location={productLocation}
				productListId={productLocation}
				variant={variant}
			/>
		</ViewPromotion>
	)
}
