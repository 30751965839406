'use client'

import type { CrossSellingClientProps } from 'cross-selling/types'
import { getProductCardImagesSizes } from 'cross-selling/utils/getProductCardImagesSizes/getProductCardImagesSizes'
import { mergeProductData } from 'cross-selling/utils/mergeProductData'
import { Carousel } from 'fukku/Carousel/components/Carousel'
import { CarouselVariants } from 'fukku/Carousel/types'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { ClientProductCard } from 'product-card/components/client'
import { getProductCardLCrossSelling } from 'product-card/constants/settings/ProductCardL/getProductCardLCrossSelling'
import { getProductCardSCrossSelling } from 'product-card/constants/settings/ProductCardS/getProductCardSCrossSelling'
import { ProductCardConsumerLayoutProvider } from 'product-card/context/ProductCardConsumerLayoutContext'
import type { ResponsiveProductCardSettings } from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'
import { ProductCardImagesSizesProvider } from 'product-card/context/ProductCardImagesSizesContext'
import { ProductDataProvider } from 'product-card/context/ProductDataContext'
import { ProductCardIcon } from 'product-card/types'
import { useMemo } from 'react'

export const ClientCrossSelling = ({
	crossSellingItems,
	title,
	variant = CarouselVariants.VARIANT2,
	imageSortCriteria,
	productsData,
	className,
	location,
	productListId,
	hasFavoriteButton,
	hasSizeSheetButton = true,
	'data-testid': dataTestId,
	showDiscount,
}: CrossSellingClientProps) => {
	const previouslyFetchedProducts =
		productsData && mergeProductData({ crossSellingItems, productsData })

	const productCardImagesSizes = getProductCardImagesSizes()
	const {
		country: { hasOnlineSale },
	} = useMasterData()
	const settings: ResponsiveProductCardSettings = useMemo(() => {
		const crossSellingSettings = {
			hasSizeSheetButton,
			showActionIcon: hasFavoriteButton
				? ProductCardIcon.Favorite
				: ProductCardIcon.None,
		}
		const context = { hasOnlineSale }
		return {
			small: {
				...getProductCardSCrossSelling(context),
				...crossSellingSettings,
			},
			medium: {
				...getProductCardSCrossSelling(context),
				...crossSellingSettings,
			},
			large: {
				...getProductCardLCrossSelling(context),
				...crossSellingSettings,
			},
		}
	}, [hasSizeSheetButton, hasFavoriteButton])

	return (
		<ProductCardConsumerLayoutProvider settings={settings}>
			<ProductCardImagesSizesProvider
				defaultImageSizes={{ regular: productCardImagesSizes[variant] }}
			>
				<Carousel
					className={className}
					dataTestId={dataTestId}
					title={title}
					variant={variant}
				>
					{previouslyFetchedProducts
						? previouslyFetchedProducts.map(
								(product) =>
									product.productData && (
										<div
											key={`${product.productId}-${product.colorId}-${product.lookId}`}
										>
											<ProductDataProvider
												key={`${product.productId}-${product.colorId}-${product.lookId}`}
												initialProductData={product.productData}
											>
												<ClientProductCard
													productId={product.productId}
													colorId={product.colorId}
													lookId={product.lookId}
													imageSortCriteria={imageSortCriteria}
													dataTestId='crossSelling'
													location={location}
													productListId={productListId}
													showDiscount={showDiscount}
													noDraggable
												/>
											</ProductDataProvider>
										</div>
									)
							)
						: crossSellingItems.map((product) => (
								<div
									key={`${product.productId}-${product.colorId}-${product.lookId}`}
								>
									<ClientProductCard
										productId={product.productId}
										colorId={product.colorId}
										lookId={product.lookId}
										imageSortCriteria={imageSortCriteria}
										dataTestId='crossSelling'
										location={location}
										productListId={productListId}
										showDiscount={showDiscount}
										noDraggable
									/>
								</div>
							))}
				</Carousel>
			</ProductCardImagesSizesProvider>
		</ProductCardConsumerLayoutProvider>
	)
}
