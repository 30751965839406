import { CarouselVariants } from 'fukku/Carousel/types'
import { Breakpoints } from 'fukku/styles/breakpoints'

export const getProductCardImagesSizes = () => {
	return {
		[CarouselVariants.VARIANT1]: [
			{
				maxWidth: `${Breakpoints.medium}px`,
				size: '94vw',
			},
			{
				maxWidth: `${Breakpoints.large}px`,
				size: '50vw',
			},
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: '25vw',
			},
			{
				size: '20vw',
			},
		],
		[CarouselVariants.VARIANT2]: [
			{
				maxWidth: `${Breakpoints.medium}px`,
				size: '50vw',
			},
			{
				maxWidth: `${Breakpoints.large}px`,
				size: '34vw',
			},
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: '20vw',
			},
			{
				size: '17vw',
			},
		],
		[CarouselVariants.VARIANT3]: [
			{
				maxWidth: `${Breakpoints.medium}px`,
				size: '50vw',
			},
			{
				maxWidth: `${Breakpoints.large}px`,
				size: '25vw',
			},
			{
				maxWidth: `${Breakpoints.extraLarge}px`,
				size: '17vw',
			},
			{
				size: '15vw',
			},
		],
		[CarouselVariants.VARIANT4]: [
			{
				size: '50vw',
			},
		],
	}
}
