import type { GetterProductCardSettings } from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'
import { ProductCardIcon } from 'product-card/types'

import { getProductCardS } from './getProductCardS'

export const getProductCardSCrossSelling: GetterProductCardSettings = (
	ctx
) => ({
	...getProductCardS(ctx),
	hasProductLabels: false,
	hasProductTags: false,
	hasColorPicker: false,
	shouldRenderSlider: false,
	shouldRenderColorSheetButton: false,
	hasSizeSheetButton: ctx.hasOnlineSale,
	shouldRenderAddButtonAsPrimary: false,
	showActionIcon: ProductCardIcon.Favorite,
})
