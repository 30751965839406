'use client'

import dynamic from 'next/dynamic'
import { useLayoutEffect, useRef, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { applyStylesIf, cx } from 'utils/cx'

import {
	type Cta,
	HERO_BANNER_SHOP_PLUS_TITLE_TYPE,
	type TitleImage,
} from '../../../../../../Home.type'
import { useTitleImageHeightOverflow } from '../../hooks/useTitleImageHeightOverflow'

import textStyles from '../../../../TextStyles.module.scss'
import styles from './HeroBannerShopContent.module.scss'

const HeroBannerShopCtas = dynamic(() =>
	import('../HeroBannerShopCtas/HeroBannerShopCtas').then(
		(mod) => mod.HeroBannerShopCtas
	)
)

const HeroBannerShopTitle = dynamic(() =>
	import('../HeroBannerShopTitle/HeroBannerShopTitle').then(
		(mod) => mod.HeroBannerShopTitle
	)
)

const HeroBannerShopTitleImage = dynamic(() =>
	import('../HeroBannerShopTitleImage/HeroBannerShopTitleImage').then(
		(mod) => mod.HeroBannerShopTitleImage
	)
)

interface HeroBannerShopContentProps {
	titleType: HERO_BANNER_SHOP_PLUS_TITLE_TYPE | null
	titleKey: string | null
	titleImage: TitleImage | null
	mobileTitleImage: TitleImage | null
	inverse: boolean
	sectionCtas: Cta[]
}

const CTAS_HEIGHT = 60

export function HeroBannerShopContent({
	titleType,
	titleKey,
	titleImage,
	mobileTitleImage,
	inverse,
	sectionCtas,
}: HeroBannerShopContentProps) {
	const contentWrapperRef = useRef<HTMLDivElement>(null)
	const { isLargeOrGreater } = useResponsive()
	const isTitleImage =
		titleType === HERO_BANNER_SHOP_PLUS_TITLE_TYPE.IMAGE &&
		titleImage &&
		mobileTitleImage
	const applyImageHeightOverflow = isTitleImage && !isLargeOrGreater
	const [isFirstRender, setIsFirstRender] = useState(true)

	const { isImageHeightOverflowing, maxHeight } = useTitleImageHeightOverflow({
		imageWrapperRef: contentWrapperRef,
		imageAspectRatio: applyImageHeightOverflow
			? mobileTitleImage.aspectRatio
			: null,
		imageRelativeWidth: applyImageHeightOverflow
			? mobileTitleImage.mobileWidth
			: null,
		padding: CTAS_HEIGHT,
	})

	useLayoutEffect(() => {
		setIsFirstRender(false)
	}, [])

	return (
		<div
			className={cx(
				styles.heroBannerTextCenterWrapper,
				applyStylesIf(Boolean(isTitleImage), styles.titleImageContainer),
				applyStylesIf(!isTitleImage && inverse, textStyles.textShadow)
			)}
			ref={contentWrapperRef}
		>
			{isFirstRender ? null : (
				<>
					{isTitleImage ? (
						<HeroBannerShopTitleImage
							titleImage={titleImage}
							mobileTitleImage={mobileTitleImage}
							isLargeOrGreater={isLargeOrGreater}
							isImageHeightOverflowing={isImageHeightOverflowing}
							maxHeight={maxHeight}
						/>
					) : (
						<HeroBannerShopTitle
							titleType={titleType}
							titleKey={titleKey}
							inverse={inverse}
							isLargeOrGreater={isLargeOrGreater}
						/>
					)}
					<HeroBannerShopCtas sectionCtas={sectionCtas} inverse={inverse} />
				</>
			)}
		</div>
	)
}
