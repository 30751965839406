import type { GetterProductCardSettings } from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'

import { getProductCardL } from './getProductCardL'

export const getProductCardLCrossSelling: GetterProductCardSettings = (
	ctx
) => ({
	...getProductCardL(ctx),
	showPriceHistory: true,
	hasProductLabels: false,
	hasProductTags: false,
	shouldRenderAddButtonAsPrimary: true,
	hasColorPicker: false,
	shouldRenderColorSheetButton: false,
})
