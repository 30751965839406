import type { Promotion } from 'analytics/types/EEC'

import type {
	CarouselBanner,
	CommCarouselItem,
	CommsBanner,
	CrossSellingCarousel,
	FamilyBannerShop,
	HeroBannerCtas,
	HeroBannerPrimaryLines,
	HeroBannerPromo,
	HeroBannerSecondaryLines,
	HeroBannerShop,
	HeroBannerShopMultibrand,
	HeroBannerShopSales,
	HeroBannerShopType,
	LineBanner,
	LinesDirectAccess,
	MangoLikesYouBanner,
	PROMOTIONAL_BANNER_TYPE,
	PromotionalBanner,
} from '../Home.type.ts'

export type PromotionFactorySections =
	| CommsBanner
	| HeroBannerCtas
	| HeroBannerPromo
	| LinesDirectAccess
	| CrossSellingCarousel
	| CommCarouselItem
	| HeroBannerShop<HeroBannerShopType>
	| HeroBannerShopMultibrand
	| FamilyBannerShop
	| HeroBannerShopSales<HeroBannerShopType>
	| MangoLikesYouBanner
	| PromotionalBanner<PROMOTIONAL_BANNER_TYPE>
	| CarouselBanner
	| LineBanner
	| HeroBannerPrimaryLines
	| HeroBannerSecondaryLines

export function promotionFactory(
	section: PromotionFactorySections,
	slot: string
): (analyticsId: string) => Promotion {
	return (analyticsId: string) => ({
		promotion_id: section.uid,
		promotion_name: section.promotionName,
		creative_name: `${section.sectionType}-${analyticsId}`,
		creative_slot: slot,
	})
}
