'use client'

import type { Position } from 'fukku/Tooltip/Tooltip'
import dynamic from 'next/dynamic'
import { useRef } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import animationStyles from '../../MotionAnimations.module.scss'
import styles from './InfoTooltip.module.scss'

const IconInfoL = dynamic(() =>
	import('icons/components/IconInfoL').then((mod) => mod.IconInfoL)
)

const Tooltip = dynamic(() =>
	import('fukku/Tooltip').then((mod) => mod.Tooltip)
)

interface InfoTooltipProps {
	title: string
	inverse: boolean
	showInfo: boolean
	infoText: string
	className?: string
	position?: Position
}

export function InfoTooltip({
	title,
	inverse,
	showInfo,
	infoText,
	className,
	position = 'top',
}: InfoTooltipProps) {
	const boundaryRef = useRef(null)

	if (!showInfo) {
		return <></>
	}

	return (
		<div ref={boundaryRef} className={cx(className, styles.boundary)}>
			<Tooltip
				position={position}
				content={<p>{infoText}</p>}
				boundary={boundaryRef}
			>
				<button
					title={title}
					className={cx(
						animationStyles.opacityFadeIn,
						styles.infoIconButton,
						applyStylesIf(inverse, styles.inverse)
					)}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
					}}
				>
					<IconInfoL />
				</button>
			</Tooltip>
		</div>
	)
}
