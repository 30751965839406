export enum StatusCode {
	SUCCESS = 200,
	CREATED = 201,
	ACCEPTED = 202,
	NO_CONTENT = 204,
	MULTI_STATUS = 207,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	CONFLICT = 409,
	UNPROCESSABLE_ENTITY = 422,
	INTERNAL_SERVER_ERROR = 500,
}
