'use client'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { type PointOfInterestImage, type Video } from 'landings/Home/Home.type'
import dynamic from 'next/dynamic'
import { useEffect, useRef, useState } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { HeroBannerImage } from '../HeroBannerImage/HeroBannerImage'
import { useHeroBannerVideoOrientation } from './hooks/useHeroBannerVideoOrientation'

import animationStyles from '../../../MotionAnimations.module.scss'
import styles from './HeroBannerVideo.module.scss'

const ButtonIconFloating = dynamic(() =>
	import('fukku/Button/Icon/Floating').then((mod) => mod.ButtonIconFloating)
)

const IconPauseL = dynamic(() =>
	import('icons/components/IconPauseL').then((mod) => mod.IconPauseL)
)

const IconPlayL = dynamic(() =>
	import('icons/components/IconPlayL').then((mod) => mod.IconPlayL)
)

type HeroBannerVideoProps = {
	disableAutoplay: boolean
	priority: boolean
	videoButtonClassName?: string
	videoButtonTitle?: {
		play: string
		pause: string
	}
	video?: {
		videoPortrait?: Video
		videoLandscape?: Video
		videoPlaceholder?: PointOfInterestImage | null
	}
	inverse?: boolean
	loop?: boolean
	onVideoCanPlay?: () => void
}

export const REDUCED_MOTION_NO_PREFERENCE_QUERY =
	'(prefers-reduced-motion: no-preference)'

export function HeroBannerVideo({
	disableAutoplay,
	priority,
	videoButtonClassName,
	videoButtonTitle,
	inverse = false,
	video = {},
	loop = true,
	onVideoCanPlay,
}: HeroBannerVideoProps) {
	const { videoPortrait, videoLandscape, videoPlaceholder } = video
	const isNoPreferenceMotion =
		typeof window !== 'undefined' &&
		window.matchMedia(REDUCED_MOTION_NO_PREFERENCE_QUERY).matches
	const videoRef = useRef<HTMLVideoElement>(null)
	const videoContainerRef = useRef<HTMLDivElement>(null)
	const entry = useIntersectionObserver(videoContainerRef, {
		threshold: 0.25,
	})
	const { hasChangedOrientation, showPortraitVideo, currentTime } =
		useHeroBannerVideoOrientation(videoRef)
	const isVideoVisible = Boolean(entry?.isIntersecting)
	const allowVideo = typeof entry?.isIntersecting === 'boolean'
	const isAutoPlay = isNoPreferenceMotion && isVideoVisible && !disableAutoplay

	const [canPlay, setCanPlay] = useState<boolean>(false)
	const [isPlayClicked, setIsPlayClicked] = useState<boolean>(false)
	const [isPlaying, setIsPlaying] = useState<boolean>(false)

	const autoPlay = hasChangedOrientation ? isPlaying : isAutoPlay
	const sourceVideo = showPortraitVideo ? videoPortrait : videoLandscape

	useEffect(() => {
		if (allowVideo) {
			setIsPlayClicked(isNoPreferenceMotion)
			setIsPlaying(isAutoPlay)
			if (isAutoPlay && videoRef.current) {
				videoRef.current.play()
			}
		}
	}, [allowVideo])

	useEffect(() => {
		if (videoRef.current && hasChangedOrientation) {
			videoRef.current.currentTime = currentTime
			if (isPlaying) {
				videoRef.current.play()
			}
		}
	}, [hasChangedOrientation])

	useEffect(() => {
		if (!videoRef.current) {
			return
		}
		if (isVideoVisible && isPlayClicked && !isPlaying) {
			videoRef.current.play()
			setIsPlaying(true)
		} else if (!isVideoVisible && isPlaying) {
			videoRef.current.pause()
			setIsPlaying(false)
		} else {
			return
		}
	}, [isVideoVisible])

	const handlePlayPause: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (!videoRef.current) {
			return
		}
		if (isPlaying) {
			videoRef.current.pause()
			setIsPlayClicked(false)
			setIsPlaying(false)
		} else {
			videoRef.current.play()
			setIsPlayClicked(true)
			setIsPlaying(true)
		}
	}

	const handleVideoEnd = () => {
		setIsPlayClicked(false)
	}

	const handleVideoCanPlay = () => {
		setCanPlay(true)
		if (onVideoCanPlay) {
			onVideoCanPlay()
		}
	}

	return (
		<div className={styles.videoContainer} ref={videoContainerRef}>
			{allowVideo && (
				<video
					key={sourceVideo?.url}
					autoPlay={autoPlay}
					loop={loop}
					muted
					playsInline
					ref={videoRef}
					onEnded={handleVideoEnd}
					className={cx(styles.video, applyStylesIf(!canPlay, styles.hidden))}
					data-testid='landings.home.heroBannerShop.video'
					onCanPlay={handleVideoCanPlay}
				>
					<source
						src={sourceVideo?.url}
						type={sourceVideo?.contentType}
						data-testid='landings.home.heroBannerShop.source'
					/>
					<track kind='captions' default />
				</video>
			)}
			{!canPlay && (
				<HeroBannerImage image={videoPlaceholder} priority={priority} />
			)}
			{canPlay && (
				<ButtonIconFloating
					className={cx(
						videoButtonClassName,
						animationStyles.opacityFadeIn,
						styles.videoButton,
						applyStylesIf(inverse, styles.inverse)
					)}
					onClick={handlePlayPause}
					title={isPlaying ? videoButtonTitle?.pause : videoButtonTitle?.play}
				>
					{isPlaying ? (
						<IconPauseL height={16} width={16} />
					) : (
						<IconPlayL height={16} width={16} />
					)}
				</ButtonIconFloating>
			)}
		</div>
	)
}
