'use client'

import {
	type Cta,
	HeroBannerShopType,
	type PointOfInterestImage,
	type Video,
} from 'landings/Home/Home.type'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'

import { ButtonCtas } from '../../../../ctas/ButtonCtas/ButtonCtas'
import { HeroBannerVideo } from '../../../components/HeroBannerVideo/HeroBannerVideo'

import animationStyles from '../../../../MotionAnimations.module.scss'
import styles from './HeroBannerShopSalesContent.module.scss'

const HeroBannerShopSalesText = dynamic(() =>
	import('../HeroBannerShopSalesText/HeroBannerShopSalesText').then(
		(mod) => mod.HeroBannerShopSalesText
	)
)

interface HeroBannerShopSalesContentProps {
	isCicd: boolean
	isPrioritySlot: boolean
	type: HeroBannerShopType
	video: {
		videoPortrait?: Video
		videoLandscape?: Video
		videoPlaceholder?: PointOfInterestImage | null
	} | null
	videoButtonTitle: {
		play: string
		pause: string
	}
	titleKey: string
	inverse: boolean
	ctas: Cta[]
	subtitleTopKey?: string
	discount?: string
	subtitleBottomGroup?: {
		titleKey: string
		subtitleKey: string
	}[]
	subtitleBottomKey?: string
}

export const HeroBannerShopSalesContent = ({
	isCicd,
	isPrioritySlot,
	type,
	video,
	videoButtonTitle,
	subtitleTopKey,
	titleKey,
	discount,
	subtitleBottomGroup,
	subtitleBottomKey,
	inverse,
	ctas,
}: HeroBannerShopSalesContentProps) => {
	const isVideo = type === HeroBannerShopType.video
	const { isLargeOrGreater } = useResponsive()
	const [canRenderText, setCanRenderText] = useState(!isVideo)

	const handleVideoCanPlay = () => {
		setCanRenderText(true)
	}

	return (
		<>
			{isVideo && video ? (
				<HeroBannerVideo
					disableAutoplay={isCicd}
					priority={isPrioritySlot}
					video={video}
					inverse={inverse}
					videoButtonTitle={videoButtonTitle}
					videoButtonClassName={styles.videoButton}
					onVideoCanPlay={handleVideoCanPlay}
				/>
			) : null}
			{canRenderText ? (
				<div
					className={cx(styles.contentWrapper, animationStyles.opacityFadeIn)}
				>
					<HeroBannerShopSalesText
						subtitleTopKey={subtitleTopKey}
						titleKey={titleKey}
						discount={discount}
						subtitleBottomGroup={subtitleBottomGroup}
						subtitleBottomKey={subtitleBottomKey}
						inverse={inverse}
						isLargeOrGreater={isLargeOrGreater}
					/>
					<ButtonCtas
						ctas={ctas}
						className={styles.ctaWrapper}
						buttonClassName={styles.buttonCta}
						listenTextOverflow
					/>
				</div>
			) : null}
		</>
	)
}
