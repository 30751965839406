import type { CrossSellingServiceResponse } from 'cross-selling/types'
import { fetcher } from 'services/rest/fetcher'
import { type BrandId, OutletBrand, ShopBrand } from 'types/brands'
import { Channel } from 'types/masterData'
import { env } from 'utils/envManager'

interface GetBestSellerProductsProps {
	countryISO: string
	language: string
	brand: BrandId | null
	channel: Channel
	externalId: string
}
export async function getBestSellerProducts({
	countryISO,
	language,
	brand,
	channel,
	externalId,
}: GetBestSellerProductsProps): Promise<CrossSellingServiceResponse[]> {
	const brandId =
		brand ?? channel === Channel.Shop ? ShopBrand.she : OutletBrand.outlet
	const path = `/ws-personalization/api/collaborative/v1/recommendations/${countryISO}`
	const url = new URL(path, env.NEXT_PUBLIC_SITE_BASE_URL)
	const searchParams = new URLSearchParams({
		language,
		brand_id: brandId,
	})
	externalId && searchParams.append('device_id', externalId)
	url.search = searchParams.toString()
	return fetcher<CrossSellingServiceResponse[]>(url.href)
}
