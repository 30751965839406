import type { Product } from 'product/types'

import type { CrossSellingServiceResponse } from '../types'

interface MergeProductDataProps {
	crossSellingItems: CrossSellingServiceResponse[]
	productsData: Product[]
}

export const mergeProductData = ({
	crossSellingItems,
	productsData,
}: MergeProductDataProps) => {
	return crossSellingItems
		.map((crossSellingItem) => {
			const detail = productsData.find(
				(productData) => productData.id === crossSellingItem.productId
			)

			return { ...crossSellingItem, productData: detail }
		})
		.filter((crossSellingItem) => crossSellingItem.productData !== undefined)
}
